import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['options', 'chartData'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    options: {
      deep: true,
      handler () {
        this.updateChart()
        this.renderChart(this.chartData, this.options)
      }
    }
  },
  methods: {
    updateChart () {
      this.$data._chart.options = this.options
      this.$data._chart.update()
    }
  }
}
