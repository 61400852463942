<template>
  <div class="small" style="margin-top: -5px;">
    <span v-if='product_mode == "pro"'>
      <line-chart v-if='showChart && isOnline' :chart-id='"pro_db_" + farmId' :chart-data="datacollection" :options="options"></line-chart>
      <div v-else class="text-secondary fI-slow" role="status">
         <div class="loaderDashboard"></div>
         <div class="small pulsate loaderTxtDashboard" style="margin-bottom:57px;"><translate>INITIALIZING</translate></div>
      </div>
    </span>
    <span v-else>
      <line-chart v-if='showChart' :chart-id='"db_" + farmId' :chart-data="datacollection" :options="options"></line-chart>
      <div v-else class="text-secondary fI-slow" role="status">
         <div class="loaderDashboard"></div>
         <div class="small pulsate loaderTxtDashboard"><translate>INITIALIZING</translate></div>
      </div>
    </span>
  </div>
</template>

<script>
import LineChart from '@/components/widgets/charts/Line.js'
import FarmStat from '@/models/farm-stat'
import { FARM_STATS__READ } from '@/store/actions.type'
import { PRODUCT_MODE } from '@/common/config'

export default {
  components: {
    LineChart
  },
  props: ['chartColors', 'liveStats'],
  data () {
    return {
      showChart: false,
      product_mode: PRODUCT_MODE,
      timeNow: Math.floor(Date.now() / 1000)
    }
  },
  beforeDestroy () {
  },
  computed: {
    farmId () {
      return this.$route.params.farm_id
    },
    allFarmStats () {
      if (this.isMobile()) {
        return FarmStat.lastInRangeForFarm(this.$route.params.farm_id, 15)
      } else {
        return FarmStat.lastInRangeForFarm(this.$route.params.farm_id, 30)
      }
    },
    latestStatMessage () {
      return FarmStat.lastForFarm(this.$route.params.farm_id)
    },
    isOnline () {
      return this.latestStatMessage && (this.latestStatMessage.created > (this.timeNow - 180))
    },
    labels () {
      const labels = this.allFarmStats.map(stat => new Date(stat.created * 1000))
      return labels
    },
    hashrateAvgs () {
      let hashrateAvgs

      if (this.containsZH) {
        hashrateAvgs = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit)
          return this.scaledHashrate(stat.hashrateTotalAsTH, multi).toFixed(10)
        })
      } else if (this.containsEH) {
        hashrateAvgs = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 1
          return this.scaledHashrate(stat.hashrateTotalAsTH, multi).toFixed(10)
        })
      } else if (this.containsPH) {
        hashrateAvgs = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 2
          return this.scaledHashrate(stat.hashrateTotalAsTH, multi).toFixed(10)
        })
      } else {
        hashrateAvgs = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 3
          return this.scaledHashrate(stat.hashrateTotalAsTH, multi).toFixed(10)
        })
      }
      return hashrateAvgs
    },
    hashrateAvgsPool () {
      let hashrateAvgsPool

      if (this.containsZH) {
        hashrateAvgsPool = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit)
          return this.scaledHashrate(stat.hashratePoolAsTH, multi).toFixed(10)
        })
      } else if (this.containsEH) {
        hashrateAvgsPool = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 1
          return this.scaledHashrate(stat.hashratePoolAsTH, multi).toFixed(10)
        })
      } else if (this.containsPH) {
        hashrateAvgsPool = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 2
          return this.scaledHashrate(stat.hashratePoolAsTH, multi).toFixed(10)
        })
      } else {
        hashrateAvgsPool = this.allFarmStats.map((stat) => {
          const multi = this.fetchMulti(stat.hashrate_unit) - 3
          return this.scaledHashrate(stat.hashratePoolAsTH, multi).toFixed(10)
        })
      }
      return hashrateAvgsPool
    },
    containsZH () {
      return this.allFarmStats.some(stat => stat.hashrate_unit === 'ZH')
    },
    containsEH () {
      return this.allFarmStats.some(stat => stat.hashrate_unit === 'EH')
    },
    containsPH () {
      return this.allFarmStats.some(stat => stat.hashrate_unit === 'PH')
    },
    hashrateScales () {
      return ['ZH', 'EH', 'PH', 'TH']
    },
    temperatureAvgs () {
      const temperatureAvgs = this.allFarmStats.map(stat => stat.temperatureAvg.toFixed(0))
      return temperatureAvgs
    },
    fanspeedAvgs () {
      return this.allFarmStats.map(stat => stat.fanspeedAvg.toFixed(0))
    },
    hashrateMax () {
      let rate
      if (this.product_mode !== 'pro') {
        rate = Math.round(Math.max.apply(null, this.hashrateAvgs))
      } else {
        if (Math.round(Math.max.apply(null, this.hashrateAvgs)) > Math.round(Math.max.apply(null, this.hashrateAvgsPool))) {
          rate = Math.round(Math.max.apply(null, this.hashrateAvgs))
        } else {
          rate = Math.round(Math.max.apply(null, this.hashrateAvgsPool))
        }
      }
      return (Math.round((rate * 1.2)) || 5)
    },
    hashrateMin () {
      let rate
      if (this.product_mode !== 'pro') {
        rate = Math.round(Math.min.apply(null, this.hashrateAvgs))
      } else {
        if (Math.round(Math.min.apply(null, this.hashrateAvgs)) < Math.round(Math.min.apply(null, this.hashrateAvgsPool))) {
          rate = Math.round(Math.min.apply(null, this.hashrateAvgs))
        } else {
          rate = Math.round(Math.min.apply(null, this.hashrateAvgsPool))
        }
      }
      return (Math.round((rate * 0.8)) || 0)
    },
    calculateStepSize () {
      let stepSize
      if (this.product_mode !== 'pro') {
        stepSize = Math.round((Math.max.apply(null, this.hashrateAvgs)) * 1.2) - Math.round((Math.min.apply(null, this.hashrateAvgs) * 0.8))
      } else {
        if (Math.round(Math.max.apply(null, this.hashrateAvgs)) > Math.round(Math.max.apply(null, this.hashrateAvgsPool)) && Math.round(Math.min.apply(null, this.hashrateAvgs)) < Math.round(Math.min.apply(null, this.hashrateAvgsPool))) {
          stepSize = Math.round((Math.max.apply(null, this.hashrateAvgs)) * 1.2) - Math.round((Math.min.apply(null, this.hashrateAvgs) * 0.8))
        }
        if (Math.round(Math.max.apply(null, this.hashrateAvgs)) < Math.round(Math.max.apply(null, this.hashrateAvgsPool)) && Math.round(Math.min.apply(null, this.hashrateAvgs)) > Math.round(Math.min.apply(null, this.hashrateAvgsPool))) {
          stepSize = Math.round((Math.max.apply(null, this.hashrateAvgsPool)) * 1.2) - Math.round((Math.min.apply(null, this.hashrateAvgsPool) * 0.8))
        }
        if (Math.round(Math.max.apply(null, this.hashrateAvgs)) > Math.round(Math.max.apply(null, this.hashrateAvgsPool)) && Math.round(Math.min.apply(null, this.hashrateAvgs)) > Math.round(Math.min.apply(null, this.hashrateAvgsPool))) {
          stepSize = Math.round((Math.max.apply(null, this.hashrateAvgs)) * 1.2) - Math.round((Math.min.apply(null, this.hashrateAvgsPool) * 0.8))
        }
        if (Math.round(Math.max.apply(null, this.hashrateAvgs)) < Math.round(Math.max.apply(null, this.hashrateAvgsPool)) && Math.round(Math.min.apply(null, this.hashrateAvgs)) < Math.round(Math.min.apply(null, this.hashrateAvgsPool))) {
          stepSize = Math.round((Math.max.apply(null, this.hashrateAvgsPool)) * 1.2) - Math.round((Math.min.apply(null, this.hashrateAvgs) * 0.8))
        }
      }
      return Math.round(stepSize / 8)
    },
    datacollection () {
      if (this.product_mode !== 'pro') {
        return {
          labels: this.labels,
          datasets: [{
            label: `${this.chartTitles()[0]} (${this.latestStatMessage.hashrate_unit}/s)`,
            lineTension: 0,
            data: this.hashrateAvgs,
            drawticks: true,
            fill: false,
            backgroundColor: '#15723f',
            borderColor: '#15723f',
            yAxisID: 'y-axis-1',
            borderWidth: 1
          },
          {
            label: `${this.chartTitles()[1]} (%)`,
            lineTension: 0,
            data: this.fanspeedAvgs,
            drawticks: true,
            fill: false,
            borderColor: '#71751d',
            backgroundColor: '#71751d',
            yAxisID: 'y-axis-2',
            borderDash: [1, 3],
            borderWidth: 1
          },
          {
            label: `${this.chartTitles()[2]} (°C)`,
            lineTension: 0,
            data: this.temperatureAvgs,
            drawticks: true,
            fill: false,
            borderColor: '#194698',
            backgroundColor: '#194698',
            yAxisID: 'y-axis-2',
            borderDash: [1, 3],
            borderWidth: 1
          }]
        }
      } else {
        return {
          labels: this.labels,
          datasets: [{
            label: `${this.chartTitles()[0]} (${this.latestStatMessage.hashrate_unit}/s)`,
            lineTension: 0,
            data: this.hashrateAvgs,
            drawticks: true,
            fill: false,
            backgroundColor: '#15723f',
            borderColor: '#15723f',
            yAxisID: 'y-axis-1',
            borderWidth: 1
          },
          {
            label: `${this.chartTitles()[3]} (${this.latestStatMessage.hashrate_unit}/s)`,
            lineTension: 0,
            data: this.hashrateAvgsPool,
            drawticks: true,
            fill: false,
            backgroundColor: '#0f6234',
            borderColor: '#0f6234',
            yAxisID: 'y-axis-1',
            borderWidth: 1
          },
          {
            label: `${this.chartTitles()[1]} (%)`,
            lineTension: 0,
            data: this.fanspeedAvgs,
            drawticks: true,
            fill: false,
            borderColor: '#71751d',
            backgroundColor: '#71751d',
            yAxisID: 'y-axis-2',
            borderDash: [1, 3],
            borderWidth: 1
          },
          {
            label: `${this.chartTitles()[2]} (°C)`,
            lineTension: 0,
            data: this.temperatureAvgs,
            drawticks: true,
            fill: false,
            borderColor: '#194698',
            backgroundColor: '#194698',
            yAxisID: 'y-axis-2',
            borderDash: [1, 3],
            borderWidth: 1
          }]
        }
      }
    },
    options () {
      if (!this.isMobile()) {
        const options = {
          animation: false,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: (this.product_mode === 'bos' ? 'bottom' : 'top'),
            labels: {
              padding: (this.product_mode === 'bos' ? 30 : 15),
              usePointStyle: true
            }
          },
          title: {
            display: (this.product_mode === 'bos'),
            lineHeight: 1,
            fontColor: '#727272',
            fontStyle: 'normal',
            text: '[ ' + this.$gettext('FARM MONITOR - SHOWING THE LAST \'60\' MINUTES') + ' ]'
          },
          tooltips: {
            mode: 'nearest',
            intersect: true
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: 'rgba(255,255,255, 0.05)'
                },
                drawticks: false,
                display: false,
                scaleLabel: {
                  display: false,
                  labelString: this.$gettext('T i m e (mins)')
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  color: 'rgba(255,255,255, 0.05)'
                },
                drawticks: true,
                display: true,
                position: 'left',
                id: 'y-axis-1',
                scaleLabel: {
                  display: true,
                  labelString: this.$gettext('H a s h r a t e') + ` (${this.latestStatMessage.hashrate_unit}/s) `
                },
                ticks: {
                  min: this.hashrateMin,
                  max: this.hashrateMax,
                  stepSize: this.calculateStepSize
                }
              },
              {
                gridLines: {
                  color: 'rgba(255,255,255, 0)'
                },
                drawticks: true,
                display: true,
                position: 'right',
                id: 'y-axis-2',
                scaleLabel: {
                  display: true,
                  labelString: this.$gettext('T e m p (°C)') + ' / ' + this.$gettext('F a n s (%)')
                },
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10
                }
              }
            ]
          }
        }
        return options
      } else {
        const options = {
          animation: false,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: (this.product_mode === 'bos' ? 'bottom' : 'top'),
            labels: {
              padding: 20,
              usePointStyle: true
            }
          },
          title: {
            display: (this.product_mode === 'bos'),
            lineHeight: 3,
            fontColor: '#525252',
            fontStyle: 'normal',
            text: '[ ' + this.$gettext('FARM STATS - THE LAST \'30\' MIN') + ' ]'
          },
          tooltips: {
            mode: 'nearest',
            intersect: true
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: 'rgba(255,255,255, 0.05)'
                },
                drawticks: false,
                display: false,
                scaleLabel: {
                  display: true,
                  labelString: this.$gettext('T i m e (mins)')
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  color: 'rgba(255,255,255, 0.05)'
                },
                drawticks: true,
                display: true,
                position: 'left',
                id: 'y-axis-1',
                scaleLabel: {
                  display: false,
                  labelString: this.$gettext('H a s h r a t e') + ` (${this.latestStatMessage.hashrate_unit}/s) `
                },
                ticks: {
                  min: this.hashrateMin,
                  max: this.hashrateMax,
                  stepSize: this.calculateStepSize
                }
              },
              {
                gridLines: {
                  color: 'rgba(255,255,255, 0)'
                },
                drawticks: true,
                display: true,
                position: 'right',
                id: 'y-axis-2',
                scaleLabel: {
                  display: false,
                  labelString: this.$gettext('T e m p (°C)') + ' / ' + this.$gettext('F a n s (%)')
                },
                ticks: {
                  min: 0,
                  max: 120,
                  stepSize: 20
                }
              }
            ]
          }
        }
        return options
      }
    }
  },
  created () {
    FarmStat.deleteAll()
    this.$store.dispatch(FARM_STATS__READ, { farmId: this.$route.params.farm_id, count: 30 }).then(() => { this.showChart = true })
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    scaledHashrate (amount, multi) {
      const scaled = amount / Math.pow(1000, multi)
      return scaled
    },
    fetchMulti (hashrateUnit) {
      return this.hashrateScales.findIndex(scale => scale === hashrateUnit)
    },
    chartTitles () {
      if (!this.isMobile()) {
        if (this.product_mode !== 'pro') {
          return ['  ' + this.$gettext('REPORTED HASHRATE'), '  ' + this.$gettext('FANSPEED AVERAGE'), '  ' + this.$gettext('TEMPERATURE AVERAGE')]
        } else {
          return ['  HR LIVE', '  FANS', '  TEMP', '  HR POOL']
        }
      } else {
        if (this.product_mode !== 'pro') {
          return ['HR', 'FANS', 'TEMP']
        } else {
          return ['HR', 'FANS', 'TEMP', 'HR POOL']
        }
      }
    }
  }
}
</script>
