<template>

  <div :class="'container-fluid dashboard-page fI mobile_' + isMobile">

    <!-- CONTENT : HEADER -->

    <FarmgodHeader>
      <div class='row'>
        <div class='col-12'>
          <Logo v-bind:link="'overview'" v-bind:title="'Powered by FarmGod.io'"/>
        </div>
      </div>
      <div class='row'>
        <section class='col alert alert-danger pulsate fI-slow' v-if='liveStatErrorMessage'>
          <b><translate>LIVESTAT-ERROR</translate> {{ liveStatErrorMessage.error_code }}</b><br/>{{ liveStatErrorMessage.error_detail }}
        </section>
        <section class='col alert alert-info pulsate fI-slow' v-if='!liveStatErrorMessage && latestStatMessage.miner_count_online === 0'>
          <b><translate>ATTENTION</translate></b><br/><translate>No miners online!</translate>
        </section>
        <NavBar v-bind:farm="farm" />
      </div>
    </FarmgodHeader>

    <!-- CONTENT : CORE -->

    <div class='row' style="margin-bottom:15px;">

      <!-- CONTENT : DASHBOARD (MOBILE) -->

      <div v-if='isMobile' class='col-12'>
        <div class="container_wrapper" id="_dashboard_" style="opacity:1;">
          <div class="container_header_detail">
            <span id="headerWrapper" class="fI">
              <img src="@/assets/img_ext/coins/BTC.png" width="21" style="margin-top: -5px; margin-bottom: -3px;" :title="$gettext('...HODL!')" />
              <div style="padding-left:6px; display:inline;">
               <span v-if="farm" style="padding-top:3px;" class="fI-slow">{{ farm.name }} <small class="color01" style="display:none;">_ {{ farm.deviceset_id }}</small></span>
               <span v-else style="padding-top:3px;" class="fI-slow"><translate>INITIALIZING</translate></span>
              </div>
            </span>
          </div>
          <div class="container_body_detail" style="margin-bottom: 0;">
            <div class="canvas-wrapper mainArea" style="margin-bottom: -10px;">
              <div>
                <Chart :chartColors='chart.chartColors' :unit='chart.unit'/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CONTENT : SIDEBAR LEFT -->

      <div v-if='!isMobile' class='col-2'>
        <SideBarLeft v-bind:farm="farm" v-bind:latestStatMessage="latestStatMessage" />
      </div>
      <div v-else class='col-7'>
        <SideBarLeft v-bind:farm="farm" v-bind:latestStatMessage="latestStatMessage" />
      </div>

      <!-- CONTENT : DASHBOARD -->

      <div v-if='!isMobile' class='col-8'>
        <div class="container_wrapper" id="_dashboard_" style="opacity:1;">
          <div class="container_header_detail">
            <span id="headerWrapper" class="fI">
              <img src="@/assets/img_ext/coins/BTC.png" width="21" style="margin-top: -5px; margin-bottom: -3px;" :title="$gettext('...HODL!')" />
              <div style="padding-left:6px; display:inline;">
               <span v-if="farm" style="padding-top:3px;" class="fI-slow">{{ farm.name }} <small class="color01" style="display:none;">_ {{ farm.deviceset_id }}</small></span>
               <span v-else style="padding-top:3px;" class="fI-slow"><translate>INITIALIZING</translate></span>
              </div>
              <span style="float: right; font-size: 0.75em; padding-right: 5px; margin-left: -100px; margin-top: 3px; cursor: not-allowed;">
                <i title="Time range" class="trans fas fa-clock" style="margin-right:5px;"></i> <span class="grey">LAST 1H</span>
              </span>
            </span>
          </div>
          <div class="container_body_detail" style="margin-bottom: -20px;">
            <div class="canvas-wrapper canvas-wrapper-pro mainArea" style="margin-bottom: -10px;">
              <Chart v-if="!showPayouts" :chartColors='chart.chartColors' :unit='chart.unit' style="margin-bottom:0px;margin-top:0px;" class="fI-slow"/>
              <!--
              <ChartPayouts v-else :chartColors='chart.chartColors' style="margin-bottom:0px;margin-top:0px;" class="fI-slow"/>
              <Stats v-if="!showPayouts" :latestStatMessage='latestStatMessage' style="margin-top: 15px;margin-bottom: -20px;" class="fI-slow"/>
              <StatsPayouts v-else :latestStatMessage='latestStatMessage' :farm='farm' style="margin-top: 15px;margin-bottom: -20px;" class="fI-slow"/>
              -->
            </div>
          </div>
        </div>
      </div>

      <!-- CONTENT : SIDEBAR RIGHT -->

      <div v-if='!isMobile' class='col-2' style="">
        <SideBarRight v-bind:latestStatMessage="latestStatMessage" v-bind:farm="farm" />
      </div>
      <div v-else class='col-5'>
        <SideBarRight v-bind:latestStatMessage="latestStatMessage" v-bind:farm="farm" />
      </div>

    </div>

    <!-- CONTENT : FOOTER -->

    <FarmgodFooter>
      <LoggedInFooter />
    </FarmgodFooter>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'

import FarmgodHeader from '@/components/layout/TheHeader'
import SideBarLeft from '@/components/layout/SidebarLeft'
import SideBarRight from '@/components/layout/SidebarRight'
import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedInFooter from '@/components/layout/Footers/LoggedIn'
import { PRODUCT_MODE } from '@/common/config'

import Farm from '@/models/farm'
import Chart from '@/components/views/Detail/Chart'
/*
import ChartPayouts from '@/components/views/Detail/ChartPayouts'
import Stats from '@/components/views/Detail/Stats'
import StatsPayouts from '@/components/views/Detail/StatsPayouts'
*/

import NavBar from '@/components/views/Detail/NavBar'
import Logo from '@/components/layout/Logo'

import { FARM__READ, LIVE_STAT__CONNECT, LIVE_STAT__DISCONNECT } from '@/store/actions.type'

export default {
  name: 'detail',
  components: { FarmgodHeader, FarmgodFooter, LoggedInFooter, Chart, NavBar, Logo, SideBarLeft, SideBarRight },
  data () {
    return {
      showPayouts: false,
      product_mode: PRODUCT_MODE,
      chart: {
        chartColors: {
          red: 'rgb(255, 99, 132)',
          orange: 'rgb(255, 159, 64)',
          yellow: 'rgb(255, 205, 86)',
          green: 'rgb(75, 192, 192)',
          blue: 'rgb(54, 162, 235)',
          purple: 'rgb(153, 102, 255)',
          grey: 'rgb(201, 203, 207)'
        },
        unit: 'TH'
      }
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1).toUpperCase()
    }
  },
  methods: {
    switchChart () {
      if (this.showPayouts) {
        this.showPayouts = false
      } else {
        this.showPayouts = true
      }
    }
  },
  beforeCreate () {
    // Farm.deleteAll()
    this.$store.dispatch(`livestats/${LIVE_STAT__DISCONNECT}`, this.$route.params.farm_id)
  },
  created () {
    this.$store.dispatch(`livestats/${LIVE_STAT__CONNECT}`, { farmId: this.$route.params.farm_id })
  },
  beforeDestroy () {
  },
  computed: {
    ...mapGetters({
      latestStatMessage: 'livestats/latestStatMessage',
      liveStatErrorMessage: 'livestats/liveStatErrorMessage'
    }),
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    farm () {
      const farm = Farm.find(this.$route.params.farm_id)
      if (farm) {
        return farm
      } else {
        this.$store.dispatch(FARM__READ, this.$route.params.farm_id)
        return null
      }
    }
  }
}
</script>
